import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { coreValues, benefits } from '/static/data/careers'
import Layout from 'layouts/index'
import Seo from 'components/seo'
import Icon from 'components/icon/index'
const EnterpriseSupportPage = () => {
  const { t } = useTranslation()

  function IconForLogo() {
    return (
      <img
        className="ml-4 w-[50px] h-[50px] lg:w-[60px] lg:h-[60px] mb-2 md:mb-1"
        src={'https://www.mlytics.com/wp-content/uploads/2022/07/logo_M_color.svg'}
      />
    )
  }

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('careers.meta.title'),
            // metaDescription: t('enterpriseSupportPage.meta.description'),
            metaUrl: 'https://www.mlytics.com/careers/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className="container flex justify-center md:flex-row pt-[4rem] pb-[8rem]">
          <div className="flex-1 text-center md:px-[15%]">
            <p className="pb-3 text-md text-blue-200">{t('careers.section1.subTitle')}</p>
            <h1 className="relative mb-6 text-primary-500 text-3xl flex lg:text-[3.5em] w-full justify-center items-center ">
              <Trans i18nKey={'careers.section1.title'}>
                <IconForLogo />
              </Trans>
            </h1>

            <p className="pb-5 text-md text-gray-600">#{t('careers.section1.hashTag')}</p>
            <a
              target="_blank"
              href="https://mlytics.teamdoor.io/"
              className="px-6 btn btn-orange mt-3"
              style={{ padding: '12px 40px' }}
              rel="noreferrer"
            >
              {t('careers.section1.explore')}
            </a>
          </div>
        </section>
        <section className="bg-primary-500  ">
          <div className="container py-20">
            <div className="flex-1 flex flex-col items-center justify-center md:block ">
              <h2 className="text-4xl text-center text-white mb-5">{t('careers.section2.title')}</h2>
              <p className="pb-7 pt-2 text-center  text-base text-white ">{t('careers.section2.content1')}</p>
              <p className="pb-7 pt-2 text-center  text-base text-white ">{t('careers.section2.content2')}</p>
            </div>
          </div>
        </section>
        <section className="container flex flex-col-reverse md:flex-row-reverse gap-0 md:gap-5 py-20">
          <div className="flex-1">
            <img src="https://www.mlytics.com/wp-content/uploads/2020/01/career.jpg" alt="careers" />
          </div>
        </section>

        <section className="container pt-20 pb-40 ">
          <div className="md:px-[20%]">
            <h2 className="mt-12 text-4xl text-primary-500 mb-5 text-center w-full">{t('careers.section3.title')}</h2>
            <div className="text-md text-center text-gray-600 leading-loose">{t('careers.section3.subTitle')}</div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3  gap-5 md:gap-7 mt-20 ">
            {coreValues.map(({ title, Icon, description }, index) => (
              <div
                key={`${index}_cardAddress`}
                className="flex flex-col justify-center items-center rounded-[20px] bg-white   "
              >
                <div className="relative">
                  <Icon className="" />
                </div>

                <h4 className=" mb-3 text-2xl md:text-3xl text-primary-500">{t(title)}</h4>
                <p className="text-gray-600 mb-6 text-sm md:text-base text-center leading-loose">{t(description)}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="bg-gray-50">
          <div className="container py-20">
            <div className="flex-1 flex flex-col items-center justify-center md:block lg:px-[18%]">
              <h2 className="text-4xl text-center text-primary-600 mb-5">{t('careers.section4.title')}</h2>
              <p className="pb-7 pt-2 text-center  text-base text-primary-500 mb-5 leading-[1.85]">
                {t('careers.section4.subTitle')}
              </p>
            </div>
            <div className="rounded-md bg-white shadow-md px-8 py-8 md:flex lg:mx-[10em] xl:mx-[16em]">
              <div className="mx-auto">
                {benefits
                  .filter((_, index) => index < 5)
                  .map(({ title, icon }, key) => (
                    <div className={`flex mb-10 ${key === 4 ? 'md:mb-0' : 'md:mb-9'}`} key={`${key}_benefitIcon`}>
                      <div className="mr-2">
                        <Icon name={icon} fontSize="16px" iconStyle={''} className="text-primary-500" />
                      </div>
                      <span className="text-sm text-primary-500">{t(title)}</span>
                    </div>
                  ))}
              </div>
              <div className="mx-auto">
                {benefits
                  .filter((_, index) => index > 4)
                  .map(({ title, icon }, key) => (
                    <div className={`flex mb-10  ${key === 4 ? 'mb-0 md:mb-0' : 'md:mb-9'}`} key={`${key}_benefitIcon`}>
                      <div className="mr-2">
                        <Icon name={icon} fontSize="16px" iconStyle={'round'} className="text-primary-500" />
                      </div>
                      <span className="text-sm text-primary-500">{t(title)}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
        <section className="container pt-20 pb-40  flex flex-col justify-center">
          <div className="md:px-[20%]">
            <h2 className="mt-12 text-4xl text-primary-500 mb-5 text-center w-full">{t('careers.section5.title')}</h2>
            <div className="text-md text-center text-primary-400">
              <Trans i18nKey={'careers.section5.subTitle'}>
                <a
                  href="mailto:career@mlytics.com"
                  className="text-secondary-600 transition-all duration-200 ease-in-out hover:text-secondary-800"
                ></a>
              </Trans>
            </div>
          </div>
          <a
            target="_blank"
            href="https://mlytics.teamdoor.io/"
            className="mx-auto px-6 btn btn-orange mt-12"
            style={{ padding: '12px 40px' }}
            rel="noreferrer"
          >
            {t('careers.section1.explore')}
          </a>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default EnterpriseSupportPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
