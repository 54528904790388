import React from 'react'

export const Heart = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
    <path
      className="fill-primary-400 scale-125"
      d="m12.1 18.55-.1.1-.11-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04 1 3.57 2.36h1.86C13.46 6 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05ZM16.5 3c-1.74 0-3.41.81-4.5 2.08C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.41 2 8.5c0 3.77 3.4 6.86 8.55 11.53L12 21.35l1.45-1.32C18.6 15.36 22 12.27 22 8.5 22 5.41 19.58 3 16.5 3Z"
    />
  </svg>
)

export const Flag = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
    <path
      className="stroke-primary-400 scale-125"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1v12Zm0 0v7"
    />
  </svg>
)

export const Chat = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
    <g className="fill-primary-400 scale-125" clipPath="url(#a)">
      <path d="M21.01 18.399c1.43-1.275 2.24-2.975 2.24-4.738 0-1.874-.877-3.627-2.47-4.936C19.229 7.45 17.176 6.75 15 6.75c-2.177 0-4.229.701-5.78 1.975-1.593 1.309-2.47 3.062-2.47 4.936s.877 3.627 2.47 4.936c1.551 1.273 3.603 1.974 5.78 1.974.29 0 .582-.012.871-.038l.495.429A9.368 9.368 0 0 0 22.5 23.25h.75v-1.596l-.22-.22a9.336 9.336 0 0 1-2.02-3.035Zm-3.662 1.43-.992-.86-.33.04c-.34.041-.683.062-1.026.062-3.722 0-6.75-2.427-6.75-5.41 0-2.984 3.028-5.411 6.75-5.411s6.75 2.427 6.75 5.41c0 1.487-.742 2.875-2.088 3.908l-.432.332.2.564a10.84 10.84 0 0 0 1.775 3.179 7.867 7.867 0 0 1-3.857-1.815Z" />
      <path d="M2.821 14.882a10.336 10.336 0 0 0 1.632-2.954l.198-.562-.432-.331C2.949 10.06 2.25 8.753 2.25 7.353c0-2.814 2.86-5.103 6.375-5.103 2.586 0 4.817 1.239 5.816 3.014a11.53 11.53 0 0 1 1.676.04c-.386-1-1.056-1.912-1.978-2.67C12.66 1.42 10.702.75 8.625.75c-2.077 0-4.035.67-5.514 1.885C1.588 3.885.75 5.56.75 7.353c0 1.678.766 3.295 2.121 4.511A8.831 8.831 0 0 1 .97 14.711l-.22.22V16.5h.75a8.936 8.936 0 0 0 3.995-.943 7.373 7.373 0 0 1-.237-1.578 7.44 7.44 0 0 1-2.437.903Z" />
    </g>
  </svg>
)
export const Chart = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
    <path
      className="fill-primary-400 scale-125"
      d="M1.125 1.5c.623 0 1.125.502 1.125 1.125v16.5c0 .623.502 1.125 1.125 1.125h19.5c.623 0 1.125.502 1.125 1.125s-.502 1.125-1.125 1.125h-19.5A3.373 3.373 0 0 1 0 19.125v-16.5C0 2.002.502 1.5 1.125 1.5ZM6 6.375c0-.623.502-1.125 1.125-1.125h9.75c.623 0 1.125.502 1.125 1.125S17.498 7.5 16.875 7.5h-9.75A1.122 1.122 0 0 1 6 6.375ZM7.125 9.75h6.75c.623 0 1.125.502 1.125 1.125S14.498 12 13.875 12h-6.75A1.122 1.122 0 0 1 6 10.875c0-.623.502-1.125 1.125-1.125Zm0 4.5h12.75c.623 0 1.125.502 1.125 1.125s-.502 1.125-1.125 1.125H7.125A1.122 1.122 0 0 1 6 15.375c0-.623.502-1.125 1.125-1.125Z"
    />
  </svg>
)

export const Setup = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
    <path
      className="fill-primary-400 scale-125"
      d="m9.452 4.293 2.87-2.87a1.1 1.1 0 0 1 1.557 0l2.87 2.87h4.058a1.101 1.101 0 0 1 1.1 1.1v4.059l2.87 2.87a1.101 1.101 0 0 1 0 1.557l-2.87 2.87v4.058a1.101 1.101 0 0 1-1.1 1.1h-4.058l-2.87 2.87a1.101 1.101 0 0 1-1.557 0l-2.87-2.87H5.394a1.101 1.101 0 0 1-1.101-1.1v-4.058l-2.87-2.87a1.101 1.101 0 0 1 0-1.557l2.87-2.87V5.394a1.1 1.1 0 0 1 1.1-1.101h4.059ZM6.495 6.494v3.87L3.759 13.1l2.736 2.736v3.87h3.87l2.735 2.736 2.736-2.736h3.87v-3.87l2.736-2.736-2.736-2.736v-3.87h-3.87L13.1 3.76l-2.736 2.735h-3.87Zm6.605 11.01a4.404 4.404 0 1 1 0-8.808 4.404 4.404 0 0 1 0 8.808Zm0-2.202a2.202 2.202 0 1 0 0-4.404 2.202 2.202 0 0 0 0 4.404Z"
    />
  </svg>
)

export const SendIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
    <path
      stroke="#000"
      className="scale-125 stroke-primary-400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.467 1.354 6.11 14.986m3.853 3.871 3.734 3.717a1.885 1.885 0 0 0 1.8.514 1.918 1.918 0 0 0 1.387-1.269l6.13-18.377A1.916 1.916 0 0 0 20.55.977L2.172 7.11A1.918 1.918 0 0 0 .903 8.582a1.884 1.884 0 0 0 .515 1.714l4.692 4.692-.155 5.942 4.008-2.073Z"
    />
  </svg>
)
