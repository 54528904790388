import { Heart, Flag, Chat, Chart, Setup, SendIcon } from '/src/components/icon/career.jsx'
export const coreValues = [
  {
    title: 'careers.section3.values.value1.title',
    description: 'careers.section3.values.value1.description',
    Icon: Heart
  },
  {
    title: 'careers.section3.values.value2.title',
    description: 'careers.section3.values.value2.description',
    Icon: Flag
  },

  {
    title: 'careers.section3.values.value3.title',
    description: 'careers.section3.values.value3.description',
    Icon: Chat
  },
  {
    title: 'careers.section3.values.value4.title',
    description: 'careers.section3.values.value4.description',
    Icon: Chart
  },
  {
    title: 'careers.section3.values.value5.title',
    description: 'careers.section3.values.value5.description',
    Icon: Setup
  },
  {
    title: 'careers.section3.values.value6.title',
    description: 'careers.section3.values.value6.description',
    Icon: SendIcon
  }
]

export const benefits = [
  {
    title: 'careers.section4.benefits.benefit1',
    icon: 'access_time_filled'
  },
  {
    title: 'careers.section4.benefits.benefit2',
    icon: 'restaurantIcon'
  },
  {
    title: 'careers.section4.benefits.benefit3',
    icon: 'cookie'
  },
  {
    title: 'careers.section4.benefits.benefit4',
    icon: 'directions_run'
  },
  {
    title: 'careers.section4.benefits.benefit5',
    icon: 'music_note'
  },
  {
    title: 'careers.section4.benefits.benefit6',
    icon: 'work '
  },
  {
    title: 'careers.section4.benefits.benefit7',
    icon: 'school '
  },
  {
    title: 'careers.section4.benefits.benefit8',
    icon: 'star '
  },
  {
    title: 'careers.section4.benefits.benefit9',
    icon: 'lightbulb'
  },
  {
    title: 'careers.section4.benefits.benefit10',
    icon: 'tag_faces'
  }
]
